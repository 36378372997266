// @ts-expect-error оставил для сборок под легаси
import React from 'react'
import './Loader.css'

export const Loader = ({
  duration, id, isBeforeSyncSlot
}:{
  duration: number, id?: string, isBeforeSyncSlot?: boolean
}) => {
  return (
    <div className="timerContainer" key={`${id}123${isBeforeSyncSlot ? 'before' : 'nobefore'}`}>
      <div className="loader" style={{ animationDuration: `${duration / 1000}s` }}>
        <div className="mark"></div>
      </div>
      <div className="time">{duration / 1000}</div>
    </div>
  )
}

