import React from 'react';
import './Image.css'

type ImageProps = {
  src: string
}

export const Image = ({ src } : ImageProps) : React.ReactElement => {

  return (
    <img className={'image'} src={src} style={{ height: 'auto', width: '100vw', display: 'block' }}/>
  )
}
