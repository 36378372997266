// @ts-expect-error import React оставил для сборок под легаси
import React, { useState, useEffect } from 'react';

export const Clock = () => {
  // Состояние для хранения текущего времени
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Функция для обновления времени
    const updateTime = () => {
      setCurrentTime(new Date());
    };

    // Обновляем время каждые 100 миллисекунд
    const timerId = setInterval(updateTime, 100);

    // Очищаем таймер при размонтировании компонента
    return () => clearInterval(timerId);
  }, []);

  // Форматируем время с ведущими нулями
  const padWithZero = (number: number) => String(number).padStart(2, '0');

  return (
    <div>
      {padWithZero(currentTime.getHours())}:
      {padWithZero(currentTime.getMinutes())}:
      {padWithZero(currentTime.getSeconds())}
    </div>
  );
};
