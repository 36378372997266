import React, { useEffect, useRef, useState } from 'react';
import './Video.css'

type VideoProps = {
  src: string
  delay?: number
  loop?: boolean
  height?: string
  width?: string
  isBeforeSyncSlot?: boolean
};

export const Video: React.FC<VideoProps> = ({
  src, delay= 0, loop = false, height, width, isBeforeSyncSlot
}): React.ReactElement => {

  const vidRef = useRef<HTMLVideoElement | null>(null);
  const [isCover, setIsCover] = useState(true)

  const disablePreview = () => {
    setIsCover(false)
  }

  if(isBeforeSyncSlot) {
    delay = 0
  }

  useEffect(() => {
    const handlePlayVideo = () => {
      if (vidRef.current && vidRef.current.play) {
        vidRef.current.play();
        setTimeout(()=> {
          disablePreview()
        }, 200)
      }
    };
    setTimeout(() => handlePlayVideo(), delay);
  }, [delay]);


  vidRef.current?.setAttribute('muted', 'true');

  return (
    <div key={`videodivloop=${'' + loop}and${src}`}>
      <div key={'videoCover'} className='videoCover' style={{ opacity: isCover ? 1 : 0 }}/>
      <video
        key={`muted=${'' + loop}and${src}`}
        ref={vidRef}
        src={src}
        muted={true}
        style={{ width: width || '100%', objectFit: 'cover', height: height || 'qqq' }}
        loop={loop || false}
        poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      />
    </div>
  );
};

Video.whyDidYouRender = true;
