import { TUseStateSlide } from '../interfaces/SlidesData.ts';
import { SCREEN_ID_STATUS, setAlertMessage } from './alertInfoController.ts';

export const configFetch = ({
  setSlidesArray
}:{
  setSlidesArray: TUseStateSlide
}

) => {

  const params = new URLSearchParams(document.location.search);
  const screenId = params.get('screenId');

  if(!screenId) {
    setAlertMessage({ key:SCREEN_ID_STATUS, value: 'Для работы нужно указать screenId панели' })
  }

  fetch(`https://s3.rentateam.ru/aivazovski/${screenId}.json`, {
    mode: 'cors'
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((jsonedData) => {
      setSlidesArray(jsonedData.slides);
    })
    .catch((error) => {
      console.error(error);
    });
}
