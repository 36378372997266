// @ts-expect-error оставил для сборок под легаси
import React from 'react';
import { Loader } from '../Loader/Loader.tsx';
import { alertMessages, FETCH_CONFIG_STATUS } from '../../controllers/alertInfoController.ts';
import { TUnionSlide } from '../../interfaces/SlidesData.ts';
import { Clock } from '../Clock/Clock.tsx';

interface IDebugInfoProps {
  currSlide: TUnionSlide
  firstTime: number
  id: string
  isBeforeSyncSlot: boolean
  position?: 'left' | 'right'
}

export const DebugInfo = ({ currSlide, firstTime, id, isBeforeSyncSlot, position = 'left' }:IDebugInfoProps) => {
  return (
    <div key={'debug-info'}
      style={{ textAlign: position, position: 'absolute', top: '30px', [`${position}`]: '30px', zIndex: 1000 }}
    >
      <div key={'text-holder'} style={{ backgroundColor: '#99FFCC', padding: '5px' }}>
        <div style={{ fontSize: '30px' }}><Clock /></div>
        {window.location.search}<br/>
        {isBeforeSyncSlot && (
          <>
            <div key={'sync-time'}>{+firstTime / 1000} сек до начала синхронизации</div>
            <div key={'curr-time'}>{+ currSlide.duration / 1000} сек время показа текущего слайда</div>
          </>
        )}

        {!isBeforeSyncSlot && (
          <div key={'sync-time-ok'}>слайды синхронизированы со временем</div>
        )}
        {alertMessages.get(FETCH_CONFIG_STATUS) && (
          <div key={'FETCH_CONFIG_STATUS'}>{alertMessages.get(FETCH_CONFIG_STATUS)}</div>
        )}
      </div>
      <Loader key={'loader'+id} duration={+currSlide.duration} isBeforeSyncSlot={isBeforeSyncSlot}/>
    </div>
  )
}
