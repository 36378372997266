// @ts-expect-error оставил для сборок под легаси
import React from 'react';
import { Loader } from '../components/Loader/Loader.tsx';
import { alertMessages, SCREEN_ID_STATUS } from '../controllers/alertInfoController.ts';

export const StartPage = ({ firstTime }:{firstTime: number}) => {

  return <div style={{ position: 'absolute', top: '30px', left: '30px' }}>
    <Loader key={'after-sync'} duration={firstTime}/>
    LOADING...

    <div>
      <a href={'/?screenId=001'}>
        {window.location.host}/?screenId=001 левая панель для 2х экранов с картошкой
      </a>
    </div>
    <div>
      <a href={'/?screenId=002'}>
        {window.location.host}/?screenId=002 правая панель для 2х экранов с картошкой
      </a>
    </div>
    <div>
      <a href={'/?screenId=004'}>{window.location.host}/?screenId=004 вертикальная панель</a>
    </div>
    <div>
      <a href={'/?screenId=006'}>{window.location.host}/?screenId=006 слайды со встроенным видео</a>
    </div>

    <div style={{ backgroundColor: '#99FFCC' }}>
      {alertMessages.get(SCREEN_ID_STATUS) && (
        <div>{alertMessages.get(SCREEN_ID_STATUS)}</div>
      )}
    </div>
  </div>
}
