// @ts-expect-error оставил для сборок под легаси
import React from 'react'
import './TextLayer.css'
import { TPinnedText } from '../../interfaces/SlidesData.ts';

type TextLayerProps = {
  textArray: Array<TPinnedText>
}


export const TextLayer = ({ textArray }:TextLayerProps) => (
  <div className={'textLayer'} style={{position: "relative", top: 0, left: 0}}>
    {textArray.map((item) => (
      <div
        key={item.top+item.left+item.text}
        style={{ position: "absolute", top: item.top, left: item.left, color: item.color, fontSize: item.size || 'none' }}
      >
        {item.text}₽
      </div>
    ))}
  </div>
)
