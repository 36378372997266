import { TIME_SLOT_MINUTES } from '../interfaces/constants.ts';
import { TSlide } from '../interfaces/SlidesData.ts';
import { TTimeoutType } from '../interfaces/TimeTypes.ts';


export let nextTimeSlotTimeout : TTimeoutType = undefined;
export let timeSlotsInterval : TTimeoutType = undefined;
export let slideTimeout : TTimeoutType = undefined;

export const getNextSlotTime = (intervalMinutes = TIME_SLOT_MINUTES) => {
  const now = new Date();
  const nextSlot = new Date(
    Math.ceil(now.getTime() / (intervalMinutes * 60 * 1000)) * (intervalMinutes * 60 * 1000)
  );

  return nextSlot.getTime() - now.getTime();
}

// eslint-disable-next-line no-unused-vars
function runPresentation(setSlide: (currentSlideIndex: number) => void, slidesArray: Array<TSlide>) {
  let currentSlideIndex = 0; // Начать сначала
  function nextSlide() {
    if(currentSlideIndex === slidesArray.length) {
      currentSlideIndex = 0;
    }
    const slide = slidesArray[currentSlideIndex];
    setSlide(currentSlideIndex); // установка
    console.log('установка слайда')
    clearTimeout(slideTimeout)
    slideTimeout = setTimeout(() => {
      currentSlideIndex++;

      nextSlide();
    }, slide.duration);
  }

  nextSlide();
}

interface IStartPresentation {
  setSlide:() => void
  slidesArray: Array<TSlide>
  // eslint-disable-next-line no-unused-vars
  setBeforeSyncSlot: (flag: boolean) => void
}

export const startPresentation = (
  { setSlide, slidesArray, setBeforeSyncSlot }:IStartPresentation

) => {
  const delayToNextSlot = getNextSlotTime();
  runPresentation(setSlide, slidesArray); // первый запуск слайдов как получится, далее уже привязываемся к слотам
  setBeforeSyncSlot(true)
  clearTimeout(nextTimeSlotTimeout)
  nextTimeSlotTimeout = setTimeout(() => {
    setBeforeSyncSlot(false)
    runPresentation(setSlide, slidesArray);
    console.log()
    // Слот начинается каждые N минут
    clearInterval(timeSlotsInterval)
    timeSlotsInterval = setInterval(runPresentation, TIME_SLOT_MINUTES * 60 * 1000, setSlide, slidesArray);

  }, delayToNextSlot);

  return { nextTimeSlotTimeout, delayToNextSlot }
}
