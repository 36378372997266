// @ts-expect-error оставил для сборок под легаси
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Image } from './components/Image/Image.tsx';
import { TextLayer } from './components/TextLayer/TextLayer.tsx';
import './App.css'
import { TImageWithText, TVideoWithDelay, TVideoWithTextAndVideo } from './interfaces/SlidesData.ts';
import { IMAGE_WITH_TEXT, IMAGE_WITH_TEXT_AND_VIDEO, VIDEO_WITH_DELAY } from './interfaces/constants.ts';
import { Video } from './components/Video/Video.tsx';
import { startPresentation } from './controllers/timeController.ts';
import { configFetch } from './controllers/confingFetch.tsx';
import { alertMessages, FETCH_CONFIG_STATUS } from './controllers/alertInfoController.ts';
import { DebugInfo } from './components/DebugInfo/DebugInfo.tsx';
import { StartPage } from './pages/StartPage.tsx';

function App() {
  const params = new URLSearchParams(document.location.search);
  const screenId = params.get('screenId');
  const [currSlideNumber, setSlideOrigin] =
    useState<number|null>(0)
  const [isBeforeSyncSlot, setBeforeSyncSlot] = useState<boolean>(false)
  const [slidesArray, setSlidesArray] =
    useState<Array<TVideoWithDelay | TImageWithText | TVideoWithTextAndVideo> | null>(null)
  const [firstTime, setFirstTime] = useState(0)
  const setSlideWithLogger = useCallback((number:number) => {

    const now = new Date();
    const isoString = now.toISOString();
    const duration = Number.isInteger(number) && slidesArray?.length && slidesArray[number].duration
    console.log(`${isoString} установлен ${number} слайд на ${duration} мс`
    )
    setSlideOrigin(number)
  }, [slidesArray])

  const timeSlotsIntervalRef =
    useRef<undefined|NodeJS.Timeout>(undefined);

  useEffect(() => {
    configFetch({ setSlidesArray })
  }, []);

  useEffect(() => {
    if(Array.isArray(slidesArray)) {

      const { nextTimeSlotTimeout, delayToNextSlot } =

      // @ts-expect-error setSlide с ума сошёл.
      startPresentation({ setSlide: setSlideWithLogger, slidesArray, setBeforeSyncSlot })
      timeSlotsIntervalRef.current = nextTimeSlotTimeout
      setFirstTime(delayToNextSlot)
    }

    return () => { clearInterval(timeSlotsIntervalRef.current)}

  }, [slidesArray, setSlideWithLogger]);

  if(!slidesArray || currSlideNumber === null) {
    return <StartPage firstTime={firstTime}/>
  }

  const currSlide: TVideoWithTextAndVideo |  TImageWithText | TVideoWithDelay = slidesArray[currSlideNumber]

  alertMessages.get(FETCH_CONFIG_STATUS)

  return (
    <div className={'screen'} key={'screen'} >
      <DebugInfo
        firstTime={firstTime}
        currSlide={currSlide}
        key={'DebugInfo'+currSlide.id}
        id={currSlide.id}
        isBeforeSyncSlot={isBeforeSyncSlot}
        position={screenId === '001' ? 'right' : 'left'}
      />
      {currSlide.type === IMAGE_WITH_TEXT && (
        <div  key={'keyIMAGE_WITH_TEXT'} className={'slideContainer'}>
          <Image src={currSlide.src} key={'imageIMAGE_WITH_TEXT' + currSlide.id}/>
          <TextLayer textArray={currSlide.pinnedText} key={'textLayerIMAGE_WITH_TEXT' + currSlide.id}/>
        </div>
      )}
      {currSlide?.type === IMAGE_WITH_TEXT_AND_VIDEO && (
        <div  key={'keyIMAGE_WITH_TEXT_AND_VIDEO'} className={'slideContainer'}>
          <Image src={currSlide.src} key={'imageXT_AND_VIDEO' + currSlide.id}/>
          <TextLayer textArray={currSlide.pinnedText} key={'textLayerT_AND_VIDEO' + currSlide.id}/>

          {currSlide.pinnedVideo.map((item) => {
            return (
              <div
                key={'video' + item.src}
                style={{ height: item.height, width: item.width, position: 'absolute', top: item.top, left: item.left }}
              >
                <Video
                  src={item.src}
                  delay={0}
                  key={'video_TEXT_AND_VIDEO' + currSlide.id}
                  loop={true}
                  height={'100%'}
                  width={'100%'}
                  isBeforeSyncSlot={isBeforeSyncSlot}
                />
              </div>
            )
          })}
        </div>
      )}
      {currSlide.type === VIDEO_WITH_DELAY && (
        <div  key={'keyVIDEO_WITH_DELAY'} >
          <Video
            src={currSlide.src}
            loop={false}
            delay={currSlide.delay}
            key={'video_VIDEO_WITH_' + currSlide.id}
            isBeforeSyncSlot={isBeforeSyncSlot}
          />
        </div>
      )}
    </div>
  )
}

export default App
